<template>
  <section class="dashboard section">
    <h4 class="title is-4">{{ selectedGroupTitle }}</h4>
    <div class="columns">
      <div class="column is-two-fifths-desktop is-max-touch">
        <EquityChart :trades="trades" />
      </div>
      <div class="column is-two-fifths-desktop is-max-touch">
        <BalanceChart :trades="trades" :privacy-mode="privacyMode" />
      </div>
      <div class="column is-flex">
        <TradesOverviewStats :trades="trades" :privacy-mode="privacyMode" />
      </div>
    </div>
    <div class="columns is-max-touch">
      <div class="column is-one-fifth-desktop is-max-touch">
        <DepositsTable :trade-journal="tradeJournal" :deposits="deposits" />
      </div>
    </div>
    <div class="columns is-max-touch">
      <div class="column is-one-fifth-desktop is-max-touch">
        <TradesTable :trade-journal="tradeJournal" :trades="trades" />
      </div>
    </div>
  </section>
</template>

<script>
import DepositsTable from "@/components/TradeJournal/DepositsTable";
import TradesTable from "@/components/TradeJournal/TradesTable";
import TradesOverviewStats from "@/components/TradeJournal/TradesOverviewStats";
import EquityChart from "@/components/TradeJournal/EquityChart";
import BalanceChart from "@/components/TradeJournal/BalanceChart";

export default {
  name: "MyTradeJournalItem",
  props: [
    "tradeJournal",
    "trades",
    "deposits",
    "group",
    "selectedGroupTitle",
    "privacyMode",
  ],
  components: {
    BalanceChart,
    EquityChart,
    TradesOverviewStats,
    TradesTable,
    DepositsTable
  },
  data() {
    return {
      isEditTrade: null,
    };
  },
};
</script>
