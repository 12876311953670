<template>
  <div id="chart-line">
    <apexchart
      ref="equitychart"
      :options="chartOptions"
      :series="series"
      height="400"
      type="line"
    ></apexchart>
  </div>
</template>

<script>
import VueApexCharts from "vue-apexcharts";

export default {
  name: "EquityChart",
  props: ["trades"],
  components: {
    apexchart: VueApexCharts,
  },
  watch: {
    // whenever trades prop changes, this function will run
    trades: {
      immediate: false,
      handler(newTrades, oldTrades) {
        console.log("trades prop changed!", { newTrades }, { oldTrades });
        const values = newTrades.map((x) => x.realized_percent);
        this.$refs.equitychart.updateSeries([
          {
            data: values.map(((sum) => (value) => (sum += value))(0)),
            // data: newTrades.map((sum => x => sum += x.realized_percent)(0))
          },
        ]);
      },
    },
  },
  async created() {
    if (this.trades.length) {
      this.series[0].data = this.trades.map((x) => x.balance);
    }
  },
  data() {
    return {
      series: [
        {
          name: "Equity",
          data: [],
        },
      ],
      chartOptions: {
        title: {
          text: "Equity Chart",
          align: "left",
          style: {
            fontSize: "14px",
            fontFamily: undefined,
            color: "#8e8e8e",
          },
        },
        subtitle: {
          text: "Equity change per trade",
          align: "left",
          style: {
            fontSize: "14px",
            fontFamily: undefined,
            color: "#8e8e8e",
          },
        },
        chart: {
          id: "equity",
          // group: 'trade',
          type: "line",
          height: 400,
        },
        dataLabels: {
          enabled: false,
          style: {
            color: "#8e8e8e",
          },
        },
        stroke: {
          curve: "smooth",
        },
        fill: {
          opacity: 1,
        },
        colors: ["#008FFB"],
        xaxis: {
          // type: 'datetime',
          categories: [],
          labels: {
            show: true,
            style: {
              color: "#8e8e8e",
            },
          },
        },
        yaxis: {
          logarithmic: false,
          labels: {
            style: {
              color: "#8e8e8e",
            },
            minWidth: 40,
            formatter: (value) => {
              return `${value.toFixed(2)}%`;
            },
          },
        },
      },
    };
  },
};
</script>
