<template>
  <div class="modal" :class="{ 'is-active': active }">
    <div class="modal-background"></div>
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">Edit Trade Journal</p>
      </header>
      <section class="modal-card-body">
        <div class="content">

          <label class="label">Title</label>
          <div class="field">
            <div class="control" style="flex: 1">
              <input v-model.number="title" class="input" type="text">
            </div>
          </div>

          <div class="field">
            <label class="label">Description</label>
            <div class="control">
              <textarea v-model.trim="description" class="textarea"></textarea>
            </div>
          </div>

        </div>
      </section>
      <footer class="modal-card-foot">
        <button class="button is-primary" :class="{ 'is-loading': loading }" :disabled="!canSave" @click="saveJournal()">Save</button>
        <button class="button is-danger" @click="closeModal()">Cancel</button>
      </footer>
    </div>
  </div>
</template>

<script>
import {db} from "@/firebase";

export default {
  name: 'EditTradeJournalModal',
  props: ['tradeJournal', 'active'],
  data() {
    return {
      title: null,
      description: null,
      loading: false
    }
  },
  // async created() {
  //   this.title = this.tradeJournal.title;
  //   this.description = this.tradeJournal.description
  // },
  watch: {
    // whenever trades prop changes, this function will run
    tradeJournal: {
      immediate: false,
      handler(newJournal, oldJournal) {
        console.log('tradeJournal prop changed!', {newJournal}, {oldJournal})
        this.title = newJournal.title;
        this.description = newJournal.description
      },
    },
    privacyMode: {
      immediate: false,
      handler(newMode, oldMode) {
        const options = {
          yaxis: {
            labels: {
              show: !newMode
            }
          },
        }
        this.$refs.balancechart.updateOptions(options)
      },
    },
  },
  computed: {
    canSave() {
      return !!(this.title && this.description);
    },
  },
  methods: {
    async saveJournal() {
      this.loading = true
      await db
          .collection('users')
          .doc(this.$store.state.userProfile.uid)
          .collection('tradejournals')
          .doc(this.tradeJournal.id)
          .update({
            title: this.title || "",
            description: this.description || ""
          })
      this.loading = false
      this.closeModal()
    },
    closeModal() {
      this.$emit('close')
    }
  }
};
</script>

<style lang="scss" scoped>
.buttons {
  width: 100%;
}
.button.is-ghost {
  color: black;
  &:hover {
    color: black;
  }
}
.brand-logo {
  height: 40px;
  max-height: none;
}
</style>
