<template>
  <div class="column">
    <table id="depositstable" class="table is-hoverable is-hidden-touch">
      <thead>
        <tr>
          <th>#</th>
          <th>Date</th>
          <th>Type</th>
          <th>Amount</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td></td>
          <td>
            <input
              v-model="newDeposit.timestamp"
              type="datetime-local"
              class="input"
            />
          </td>
          <td>
            <div class="select">
              <select v-model="newDeposit.type">
                <option value="deposit">deposit</option>
                <option value="withdraw">withdraw</option>
              </select>
            </div>
          </td>
          <td>
            <input
              v-model.number="newDeposit.amount"
              class="input is-primary m-0"
              type="number"
            />
          </td>
          <td class="">
            <button
              @click="addDeposit()"
              class="button is-primary is-fullwidth"
            >
              Add
            </button>
          </td>
        </tr>
        <tr v-for="(deposit, i) in deposits" :key="deposit.id">
          <template v-if="isEditDeposit && editDeposit.id === deposit.id">
            <td>
              <span class="">{{ i + 1 }}</span>
            </td>
            <td>
              <input
                  v-model="editDeposit.timestamp"
                  class="input"
                  type="datetime-local"
              />
            </td>
            <td>
              <div class="select">
                <select v-model="editDeposit.type">
                  <option value="deposit">deposit</option>
                  <option value="withdraw">withdraw</option>
                </select>
              </div>
            </td>
            <td>
              <input
                  v-model.number="editDeposit.amount"
                  class="input is-primary"
                  type="number"
              />
            </td>
            <td>
              <div class="field is-grouped">
                <p class="control">
                  <button
                      :disabled="!isDepositValid(editDeposit)"
                      class="button is-primary"
                      @click="updateDeposit()"
                  >
                    Update
                  </button>
                </p>
                <p class="control">
                  <button
                      class="button is-danger is-light"
                      @click="cancelEditDeposit()"
                  >
                    Cancel
                  </button>
                </p>
              </div>
            </td>
          </template>
          <template v-else>
            <td
                :class="{
                'has-background-primary-light': deposit.type === 'deposit',
                'has-background-danger-light': deposit.type === 'withdraw',
              }"
            >
              <span>{{ i + 1 }}</span>
            </td>
            <td>
              <span class="">{{ deposit.timestamp | date }}</span>
            </td>
            <td>
              <span
                  v-if="deposit.type === 'deposit'"
                  class="has-text-success has-text-weight-bold"
              >deposit</span
              >
              <span v-else class="has-text-danger has-text-weight-bold"
              >withdraw</span
              >
            </td>
            <td>
              <span class="">{{ deposit.amount }}</span>
            </td>
            <td>
              <div class="field is-grouped">
                <p class="control">
                  <button
                      class="button is-small is-primary is-light"
                      @click="startEditDeposit(deposit)"
                  >
                    Edit
                  </button>
                </p>
                <p class="control">
                  <button
                      class="button is-small is-danger is-light"
                      @click="deleteDeposit(deposit)"
                  >
                    Delete
                  </button>
                </p>
              </div>
            </td>
          </template>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { db } from "@/firebase";

export default {
  name: "DepositsTable",
  props: ["tradeJournal", "deposits", "privacyMode"],
  components: {},
  data() {
    return {
      isEditDeposit: null,
      newDeposit: {
        timestamp: new Date().toISOString().slice(0, 16),
        type: "deposit",
        amount: 0,
      },
      editDeposit: {
        id: null,
        timestamp: new Date(),
        type: "deposit",
        amount: 0,
      },
    };
  },
  async created() {},
  filters: {
    date(val) {
      if (val) {
        return val.toDate().toLocaleString();
      } else {
        return val;
      }
    },
  },
  methods: {
    isDepositValid() {
      if (!this.newDeposit.timestamp) {
        return false;
      } else if (!this.newDeposit.type) {
        return false;
      } else if (!this.newDeposit.amount) {
        return false;
      }
      return true;
    },
    async addDeposit() {
      console.log(this.newDeposit);
      await db
        .collection("users")
        .doc(this.$store.state.userProfile.uid)
        .collection("tradejournals")
        .doc(this.tradeJournal.id)
        .collection("deposits")
        .add({
          ...this.newDeposit,
          timestamp: new Date(this.newDeposit.timestamp),
        });
    },
    async updateDeposit() {
      await db
        .collection("users")
        .doc(this.$store.state.userProfile.uid)
        .collection("tradejournals")
        .doc(this.tradeJournal.id)
        .collection("deposits")
        .doc(this.editDeposit.id)
        .update({
          ...this.editDeposit,
          timestamp: new Date(this.editDeposit.timestamp),
        });
      this.isEditDeposit = false;
    },
    async deleteDeposit(deposit) {
      await db
        .collection("users")
        .doc(this.$store.state.userProfile.uid)
        .collection("tradejournals")
        .doc(this.tradeJournal.id)
        .collection("deposits")
        .doc(deposit.id)
        .delete();
    },
    startEditDeposit(deposit) {
      console.log(deposit);
      this.editDeposit = {
        ...deposit,
        id: deposit.id,
      };
      this.editDeposit.timestamp = deposit.timestamp
        .toDate()
        .toISOString()
        .slice(0, 16);
      this.isEditDeposit = true;
    },
    cancelEditDeposit() {
      this.isEditDeposit = false;
      this.editDeposit = {
        id: null,
        timestamp: new Date(),
        type: "deposit",
        amount: 0,
      };
    },
  },
};
</script>
<style lang="scss" scoped>
#depositstable {
  background-color: var(--table-background-color);
  color: var(--table-content-color);
}
#depositstable td {
  border: 1px solid;
  border-color: var(--app-background-color);
  &:hover {
    background-color: var(--app-background-color);
    border: 1px solid;
    border-color: var(--table-background-color);
  }
}
.table {
  background-color: var(--app-background-color);
}

#depositstable tr {
  border: 1px solid;
  border-color: var(--app-background-color);
  &:hover {
    background-color: var(--app-background-color);
    border: 1px solid;
    border-color: var(--table-background-color);
  }
}
.has-background-primary-light {
  background-color: var(--positive-trade-result) !important;
}
.has-background-danger-light {
  background-color: var(--negative-trade-result) !important;
}
.input[type="number"] {
  -moz-appearance: textfield;
}
.input::-webkit-outer-spin-button,
.input::-webkit-inner-spin-button {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
}
</style>
