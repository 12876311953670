<template>
  <section class="dashboard section is-full-touch is-full mobile">
    <div class="container is-fluid-desktop is-full-touch is-full-mobile">
      <div class="is-hidden-desktop is-hidden-widescreen">
        <Mobilemenu />
      </div>
      <div class="columns">
        <div class="column is-narrow-desktop is-hidden-mobile is-hidden-touch">
          <Sidebar />
        </div>
        <div class="column">
                      <div class="field is-grouped is-justify-content-left-mobile is-justify-content-right">
              <p class="control">
                <button
                  @click="togglePrivacy()"
                  class="button"
                  :class="{
                    'is-warning': privacyMode,
                    'is-success': !privacyMode,
                  }"
                >
                  <i class="fas fa-eye"></i>
                </button>
              </p>
              <p class="control">
                <button @click="editJournal()" class="button is-success">
                  Edit Journal
                </button>
              </p>
              <p class="control">
                <button @click="deleteJournal()" class="button is-danger">
                  Delete
                </button>
              </p>
            </div>
          <div>
            <div class="is-flex is-justify-content-space-between block">
              <div>
                <h1 class="title mb-1">{{ tradeJournal.title }}</h1>
                <nav aria-label="breadcrumbs" class="breadcrumb is-large">
                  <ul>
                    <li>
                      <router-link :to="{ name: 'AccountMyTradeJournals' }">
                        <span>My Trade Journals</span>
                      </router-link>
                    </li>
                  </ul>
                </nav>
                <p>{{ tradeJournal.description }}</p>
              </div>
            </div>
            <div class="columns">
              <div class="column is-2">
                <div class="columns is-multiline">
                  <div
                    v-for="(group, i) in groups"
                    :key="group"
                    class="column is-full"
                  >
                    <div
                      @click="selectGroup(group)"
                      class="card block month-card is-clickable	"
                    >
                      <div
                        class="card-content is-flex is-align-items-baseline p-5"
                      >
                        <p class="title is-4 m-0">
                          {{ group }}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="column is-10">
                <div class="columns is-multiline">
                  <div v-for="(group, i) in months" :key="group" class="column is-one-sixth">
                    <div @click="selectGroup(group)" class="card block month-card is-clickable	">
                      <div class="card-content is-flex is-align-items-baseline p-4">
                        <p class="title is-6 m-0">
                          {{ getGroupName(group).monthName }}
<!--                          <br>-->
<!--                          {{ getGroupName(group).year }}-->
                        </p>
                        <p
                          v-if="getGroupR(group) >= 0"
                          class="r-profit has-text-success"
                        >
                          {{ getGroupR(group) }}R
                        </p>
                        <p v-else class="r-profit has-text-danger">
                          {{ getGroupR(group) }}R
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
<!--            <div>-->
<!--              <button @click="deposit">Deposit</button>-->
<!--            </div>-->
            <MyTradeJournalItem
              :trade-journal="tradeJournal"
              :trades="filteredTrades"
              :deposits="filteredDeposits"
              :group="selectedGroup"
              :selected-group-title="selectedGroupTitle"
              :privacy-mode="privacyMode"
            />
          </div>
        </div>
      </div>
    </div>
    <EditTradeJournalModal
      :trade-journal="tradeJournal"
      :active="showEditJournalModal"
      @close="showEditJournalModal = false"
    />
  </section>
</template>

<script>
import Sidebar from "@/components/Sidebar.vue";
import Mobilemenu from "@/components/Mobilemenu.vue";
import { db } from "@/firebase";
import MyTradeJournalItem from "@/components/TradeJournal/MyTradeJournalItem";
import EditTradeJournalModal from "@/components/TradeJournal/EditTradeJournalModal";

export default {
  name: "AccountMyTradingJournal",
  components: {
    Sidebar,
    MyTradeJournalItem,
    EditTradeJournalModal,
    Mobilemenu,
  },
  data() {
    return {
      trades: [],
      deposits: [],
      tradeJournal: {
        title: null,
        description: null,
      },
      selectedGroup: "All",
      showEditJournalModal: false,
      privacyMode: false,
    };
  },
  async created() {
    const tradeJournal = await this.$bind(
      "tradejournal",
      db
        .collection("users")
        .doc(this.$store.state.userProfile.uid)
        .collection("tradejournals")
        .doc(this.$route.params.journal)
    );
    console.log({ tradeJournal });
    this.tradeJournal = tradeJournal;
    const tradesBindResult = await this.$bind(
      "trades",
      db
        .collection("users")
        .doc(this.$store.state.userProfile.uid)
        .collection("tradejournals")
        .doc(this.tradeJournal.id)
        .collection("trades")
        .orderBy("timestamp")
    );
    console.log({ tradesBindResult });
    await this.$bind(
        "deposits",
        db
            .collection("users")
            .doc(this.$store.state.userProfile.uid)
            .collection("tradejournals")
            .doc(this.tradeJournal.id)
            .collection("deposits")
            .orderBy("timestamp")
    );
  },
  computed: {
    selectedGroupTitle() {
      if (!this.selectedGroup) {
        return "";
      } else if (this.selectedGroup.includes("_")) {
        const [yearNumber, monthNumber] = this.selectedGroup.split("_");
        const date = new Date(yearNumber, monthNumber);
        const month = date.toLocaleString("default", { month: "long" });
        const year = date.getFullYear();
        return `${month}, ${year}`;
      } else {
        return this.selectedGroup;
      }
    },
    groups() {
      const groups = ["All"];
      this.trades.forEach((trade) => {
        const date = trade.timestamp.toDate();
        const yearString = `${date.getFullYear()}`;
        if (!groups.includes(yearString)) {
          groups.push(yearString);
        }
      });
      return groups;
    },
    months() {
      const groups = [];
      this.trades.forEach((trade) => {
        const date = trade.timestamp.toDate();
        if (!this.selectedGroup || this.selectedGroup === "All") {
          const monthString = `${date.getFullYear()}_${date.getMonth()}`;
          if (!groups.includes(monthString)) {
            groups.push(monthString);
          }
        } else {
          const yearString = `${date.getFullYear()}`;
          const monthString = `${date.getFullYear()}_${date.getMonth()}`;
          if (
            !groups.includes(monthString) &&
            yearString === this.selectedGroup
          ) {
            groups.push(monthString);
          }
        }
      });
      return groups;
    },
    filteredTrades() {
      if (this.selectedGroup === "All") {
        // no filter
        return this.trades;
      } else if (this.selectedGroup.includes("_")) {
        // month filter
        const trades = [];
        this.trades.forEach((trade) => {
          const date = trade.timestamp.toDate();
          const monthString = `${date.getFullYear()}_${date.getMonth()}`;
          if (monthString === this.selectedGroup) {
            trades.push(trade);
          }
        });
        return trades;
      } else if (this.selectedGroup) {
        // year filter
        const trades = [];
        this.trades.forEach((trade) => {
          const date = trade.timestamp.toDate();
          const yearString = `${date.getFullYear()}`;
          if (yearString === this.selectedGroup) {
            trades.push(trade);
          }
        });
        return trades;
      }
    },
    filteredDeposits() {
      if (this.selectedGroup === "All") {
        // no filter
        return this.deposits;
      } else if (this.selectedGroup.includes("_")) {
        // month filter
        const deposits = [];
        this.deposits.forEach((deposit) => {
          const date = deposit.timestamp.toDate();
          const monthString = `${date.getFullYear()}_${date.getMonth()}`;
          if (monthString === this.selectedGroup) {
            deposits.push(deposit);
          }
        });
        return deposits;
      } else if (this.selectedGroup) {
        // year filter
        const deposits = [];
        this.deposits.forEach((deposit) => {
          const date = deposit.timestamp.toDate();
          const yearString = `${date.getFullYear()}`;
          if (yearString === this.selectedGroup) {
            deposits.push(deposit);
          }
        });
        return deposits;
      }
    },
  },
  methods: {
    togglePrivacy() {
      this.privacyMode = !this.privacyMode;
    },
    editJournal() {
      this.showEditJournalModal = true;
    },
    async deleteJournal() {
      if (this.trades.length) {
        alert("Please delete all trades first before deleting the journal");
      } else {
        await db
          .collection("users")
          .doc(this.$store.state.userProfile.uid)
          .collection("tradejournals")
          .doc(this.$route.params.journal)
          .delete();
        await this.$router.push({ name: "AccountMyTradeJournals" });
      }
    },
    getGroupName(group) {
      if (group.includes("_")) {
        const year = group.split("_")[0];
        const month = group.split("_")[1];
        const monthName = new Date(year, month).toLocaleString("default", {
          month: "long",
        });
        // return `${monthName}, ${year}`;
        return { monthName, year}
      } else {
        return group;
      }
    },
    selectGroup(group) {
      if (this.selectedGroup === group) {
        this.selectedGroup = null;
      } else {
        this.selectedGroup = group;
      }
    },
    getGroupTrades(group) {
      const trades = [];
      this.trades.forEach((trade) => {
        if (group.toLowerCase() === "all") {
          trades.push(trade);
        } else {
          const date = trade.timestamp.toDate();
          const tradeMonthString = `${date.getFullYear()}_${date.getMonth()}`;
          if (group === tradeMonthString) {
            trades.push(trade);
          }
        }
      });
      return trades;
    },
    getGroupR(group) {
      return this.getGroupTrades(group)
        .reduce((x, { profit_r }) => x + profit_r, 0)
        .toFixed(2);
    },
  },
};
</script>

<style lang="scss" scoped>
.month-card {
  flex: 1;
  display: flex;
  transition: all 0.3s ease-in-out;
  .card-content {
    width: 100%;
    .title {
      flex: 1;
    }
  }

  &::after {
    content: "";
    position: absolute;
    z-index: -1;
    width: 100%;
    height: 100%;
    opacity: 0;
    border-radius: 5px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
    transition: opacity 0.3s ease-in-out;
  }

  &:hover {
    transform: scale(1.02, 1.02);

    &::after {
      opacity: 1;
    }
  }
}

@media screen and (min-width: 769px) {
  .column.is-one-sixth, .column.is-one-sixth-tablet {
    flex: none;
    width: 16.66%;
  }
}
</style>
