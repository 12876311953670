<template>
  <div id="chart-area">
    <apexchart
      ref="balancechart"
      :options="chartOptions"
      :series="series"
      height="400"
      type="area"
    ></apexchart>
  </div>
</template>

<script>
import VueApexCharts from "vue-apexcharts";

export default {
  name: "BalanceChart",
  props: ["trades", "privacyMode"],
  components: {
    apexchart: VueApexCharts,
  },
  watch: {
    // whenever trades prop changes, this function will run
    trades: {
      immediate: false,
      handler(newTrades, oldTrades) {
        console.log("trades prop changed!", { newTrades }, { oldTrades });
        this.$refs.balancechart.updateSeries([
          {
            data: newTrades.map((x) => x.balance),
          },
        ]);
      },
    },
    privacyMode: {
      immediate: false,
      handler(newMode, oldMode) {
        const options = {
          yaxis: {
            labels: {
              show: !newMode,
              style: {
                color: "#8e8e8e",
              },
            },
          },
        };
        this.$refs.balancechart.updateOptions(options);
      },
    },
  },
  async created() {
    if (this.trades.length) {
      this.series[0].data = this.trades.map((x) => x.balance);
    }
  },
  data() {
    return {
      series: [
        {
          name: "Balance",
          data: [],
        },
      ],
      chartOptions: {
        title: {
          text: "Balance Chart",
          align: "left",
          style: {
            fontSize: "14px",
            fontFamily: undefined,
            color: "#8e8e8e",
          },
        },
        subtitle: {
          text: "Account balance after each trade",
          align: "left",
          style: {
            fontSize: "14px",
            fontFamily: undefined,
            color: "#8e8e8e",
          },
        },
        chart: {
          id: "balance",
          // group: 'trade',
          type: "area",
          height: 400,
        },
        dataLabels: {
          enabled: false,
          style: {
            color: "#8e8e8e",
          },
        },
        colors: ["#00E396"],
        xaxis: {
          // type: 'datetime',
          categories: [],
          labels: {
            style: {
              color: "#8e8e8e",
            },
            show: false,
          },
        },
        yaxis: {
          style: {
            color: "#8e8e8e",
          },
          logarithmic: true,
          labels: {
            style: {
              color: "#8e8e8e",
            },
            minWidth: 40,
            offsetX: -10,
            // formatter: (value) => { return value.toFixed(2) },
          },
        },
      },
    };
  },
};
</script>
